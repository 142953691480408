import * as React from "react"
import IChildrenProps from "../interfaces/IChildrenProps";
import BasePage from "./BasePage";
import SideBar, { SideBarDataType } from "./SideBar";
import { ContentDataType, ContentPageSectionDataType } from "../data";
import SectionHeader from "./SectionHeader";
import { useLocation } from "react-router-dom";
import { aboutPageRoute, aboutTeamHashCode, teamMemberPageData } from "../data/AboutPageConstants";
import { aquaponicsPageData, heatAndPowerPageData, internetOfThingsPageData, lightingPageData, ourWorkAquaponicsHashCode, ourWorkHeatAndPowerHashCode, ourWorkInternetOfThingsHashCode, ourWorkLightingHashCode, ourWorkPageRoute } from "../data/OurWorkPageConstants";

interface ContentPageProps extends IChildrenProps {
    data: ContentPageSectionDataType
}

const RenderContent = (content: ContentDataType, index: number): React.ReactNode => {
    if (content.type == "image") {
        return <ContentImage src={content.value} key={`content-${index}`} />
    } else if (content.type == "text") {
        return <ContentText key={`content-${index}` } >{content.value}</ContentText>
    }
}

const ContentPage: React.FC<ContentPageProps> = ({ children, data }) => {
    const [active, setActive] = React.useState(0)
    const [tab, setTab] = React.useState(0)
    const { hash, pathname } = useLocation()

    // TODO: Put this somewhere else
    React.useEffect(() => {
        if (pathname == aboutPageRoute && hash == aboutTeamHashCode) {
            setActive(data.map(section => section.title).indexOf(teamMemberPageData.title))
        } else if (pathname == ourWorkPageRoute) {
            if (hash == ourWorkAquaponicsHashCode) {
                setActive(data.map(section => section.title).indexOf(aquaponicsPageData.title))
            } else if (hash == ourWorkHeatAndPowerHashCode) {
                setActive(data.map(section => section.title).indexOf(heatAndPowerPageData.title))
            } else if (hash == ourWorkInternetOfThingsHashCode) {
                setActive(data.map(section => section.title).indexOf(internetOfThingsPageData.title))
            } else if (hash == ourWorkLightingHashCode) {
                setActive(data.map(section => section.title).indexOf(lightingPageData.title))
            }
        }
    }, [])

    return <BasePage>
        <div className="grid gap-o grid-cols-1 md:grid-cols-4">
            <div className="col-span-1">
                <SideBar active={active} setActive={setActive} data={data as Array<SideBarDataType>} />
            </div>
            <div className="col-span-1 md:col-span-3">
                {data.map((item, index) => {
                    return <ContentSection key={`content-section-${index}`} active={active} index={index}>
                        <SectionHeader>{item.title}</SectionHeader>
                        {item.content !== undefined && item.content.map((content, index2) => RenderContent(content, index2))}
                        {item.members !== undefined && <div className="grid gap-0 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                            {item.members.map((member, index2) => <>
                                <ContentDiv>
                                    <ContentImage src={member.image} />
                                </ContentDiv>
                                <ContentDiv>
                                    <h2 className="text-lg md:text-xl lg:text-2xl font-bold">{member.name}</h2>
                                    <h3 className="text-base md:text-lg lg:text-xl">{member.title}</h3>
                                    <ul className="text-sm md:text-sm lg:text-base">
                                        {member.qualifications.map((qualification, index3) => <li>{qualification}</li>)}
                                    </ul>
                                </ContentDiv>
                            </>)}
                        </div>}
                        {item.tabs !== undefined && <div className="grid gap-0 grid-cols-1 md:grid-cols-2">
                            <ContentDiv>
                                <ContentImage src={item.image} />
                            </ContentDiv>
                            <ContentDiv>
                                <h3 className="text-center">Description</h3>
                                <ContentText>{item.description}</ContentText>
                            </ContentDiv>
                            <ContentDiv className="col-span-2">
                                <ContentTabSection>
                                    {item.tabs.map((item, index) => <ContentTab index={index} setTab={setTab} tab={tab} key={`content-tab-${index}`}>{item.title}</ContentTab>)}
                                </ContentTabSection>
                                {item.tabs.map((item, index) => <ContentTabContents index={index} tab={tab}>
                                    {item.content.map((content, index2) => RenderContent(content, index2))}
                                </ContentTabContents>)}
                            </ContentDiv>
                        </div>}
                    </ContentSection>
                })}
            </div>
        </div>
    </BasePage>
}

export default ContentPage

interface ContentSectionProps extends IChildrenProps {
    active: number,
    index: number
}

export const ContentSection: React.FC<ContentSectionProps> = ({ active, children, index }) => <div className={`${active == index ? "" : "hidden"} m-2 md:m-4`}>
    {children}
</div>

interface ContentDivProps extends IChildrenProps {
    className: string
}

export const ContentDiv: React.FC<ContentDivProps> = ({ children, className = "" }) => <div className={`m-4 ${className}`}>
    {children}
</div>

interface ContentTextProps extends IChildrenProps {
    className: string
}

export const ContentText: React.FC<ContentTextProps> = ({ children, className = "" }) => <p className={`whitespace-pre-wrap text-xs md:text-sm lg:text-base my-4 ${className}`}>{children}</p>

interface ContentImageProps {
    className: string,
    src: string
}

export const ContentImage: React.FC<ContentImageProps> = ({ className = "", src }) => <img src={src} className={`wfull h-auto ${className}`} />

interface ContentTabSectionProps extends IChildrenProps { }

export const ContentTabSection: React.FC<ContentTabSectionProps> = ({ children }) => {
    return <ul className="md:flex md:flex-wrap text-sm font-medium text-center text-black border-b border-gray-200">
        {children}
    </ul>
}

interface ContentTabProps extends IChildrenProps {
    index: number,
    setTab: React.Dispatch<React.SetStateAction<number>>,
    tab: number
}

export const ContentTab: React.FC<ContentTabProps> = ({ children, index, setTab, tab }) => {
    return <li className="mr-2" onClick={() => setTab(index)}>
        <a href="#" className={`w-full inline-block p-4 md:rounded-t-lg ${index == tab ? "text-white bg-primary" : "text-primary bg-gray-50 hover:bg-gray-100"}`}>{children}</a>
    </li>
}

interface ContentTabContentsProp extends IChildrenProps {
    index: number,
    tab: number
}

export const ContentTabContents: React.FC<ContentTabContentsProp> = ({ children, index, tab }) => {
    return <div className={`overflow-y-scroll ${index == tab ? "" : "hidden"}`}>
        {children}
    </div>
}