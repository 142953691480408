import * as React from "react";
import BasePage from "./BasePage";
import { HeroSectionDataType, homePageData, OfferingCardDataType, PosterSectionDataType, TeamMemberDataType } from "data/index"
import IChildrenProps from "interfaces/IChildrenProps";
import SectionHeader from "./SectionHeader";
import { aboutPageRoute, aboutTeamHashCode } from "../data/AboutPageConstants";

interface HomePageProps {

}

const HomePage: React.FC<HomePageProps> = () => {
    var teamMembers = []
    for (var i = 0; i < 4; i++) {
        let member = homePageData.ourTeamSectionData.members[i];
        if (!(homePageData.ourTeamSectionData.members[i] === undefined)) {
            teamMembers.push(member);
        }
    }

    return <BasePage>
        <HeroImageSection data={homePageData.heroSectionData} />
        <DividerSection>{homePageData.dividerContent[0]}</DividerSection>
        <OfferingsSection>
            {homePageData.offeringSectionData.cards.map(card => <OfferingCard data={card} />)}
        </OfferingsSection>
        <DividerSection>{homePageData.dividerContent[1]}</DividerSection>
        <PosterSection data={homePageData.posterSectionData} />
        <OurTeamSection data={homePageData.ourTeamSectionData}>
            {teamMembers.map(member => <TeamMemberCard data={member} />)}
        </OurTeamSection>
        <ContactFormSection />
    </BasePage>
}

export default HomePage;

interface HeroImageSectionProps {
    data: HeroSectionDataType
}

export const HeroImageSection: React.FC<HeroImageSectionProps> = ({ data }) => {
    return <div className="flex flex-col justify-start items-center md:w-full md:h-[calc(100vh-92px)] font-nav relative" id="landingImage">
        <img src={data.image} className="md:h-full w-full" />
        <div className="bg-transparent text-white absolute m-2 md:m-8 bottom-2 md:bottom-4">
            <h1 className="text-xl md:text-2xl lg:text-3xl font-bold">{data.header}</h1>
            <p className="text-base md:text-lg lg:text-xl mb-4">{data.content}</p>
            <AnchorButton className="opacity-75 hover:opacity-100" href={data.btnHref}>{data.btnLabel}</AnchorButton>
        </div>
    </div>
}

interface DividerSectionProps extends IChildrenProps {

}

export const DividerSection: React.FC<DividerSectionProps> = ({ children }) => <div className="bg-primary py-4">
    <p className="text-white text-lg md:text-xl lg:text-2xl text-center whitespace-pre">{children}</p>
</div>

interface OfferingsSectionsProps extends IChildrenProps {

}

export const OfferingsSection: React.FC<OfferingsSectionsProps> = ({ children }) => <HomePageSection className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
    {children}
</HomePageSection>

interface OfferingCardProps {
    data: OfferingCardDataType
}

export const OfferingCard: React.FC<OfferingCardProps> = ({ data }) => <div className="shadow-2xl rounded-none text-center grid">
    <div>
        <a href="#">
            <img className="w-[calc(100%-2rem)] h-auto m-4 hover:border hover:border-primary" src={data.image} />
        </a>
        <h2 className="text-xl md:text-2xl lg:text-4xl m-4">{data.title}</h2>
        <p className="text-xs md:text-sm lg:text-base m-4">{data.content}</p>
    </div>
    <AnchorButton className="self-end mb-4 mx-auto w-1/2" href={data.href}>{data.btnLabel}</AnchorButton>
</div>

interface PosterSectionProps {
    data: PosterSectionDataType
}

export const PosterSection: React.FC<PosterSectionProps> = ({ data }) => <HomePageSection>
    <img src={data.image} className="w-full h-auto" />
</HomePageSection>

interface OurTeamSectionProps extends IChildrenProps { }

export const OurTeamSection: React.FC<OurTeamSectionProps> = ({ children, data }) => <HomePageSection className="bg-zinc-300 grid">
    <SectionHeader>{data.title}</SectionHeader>
    <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-4">
        {children}
    </div>
    <AnchorButton className="self-end w-1/4 mx-auto" href={`${aboutPageRoute}${aboutTeamHashCode}`}>
        {data.btnLabel}
    </AnchorButton>
</HomePageSection>

interface TeamMemberCardProps {
    data: TeamMemberDataType
}

export const TeamMemberCard: React.FC<TeamMemberCardProps> = ({ data }) => <div className="rounded-none text-center grid">
    <img src={data.image} className="w-full h-auto mb-4" />
    <div className="border border-dark text-start p-2">
        <h3 className="text-base md:text-lg lg:text-xl">{data.name}</h3>
        <h4 className="text-sm md:text-base lg:text-lg">{data.title}</h4>
    </div>
</div>

interface ContactFormSectionProps { }

export const ContactFormSection: React.FC<ContactFormSectionProps> = () => <HomePageSection className="bg-primary grid">
    <div className="bg-white w-3/4 md:w-4/6 mx-auto text-center p-8">
        <h3 className="text-lg md:text-xl lg:text-2xl mb-4">Request a Call Back</h3>
        <form className="grid gap-4 md:gap-8 grid-cols-1 md:grid-cols-2 mb-4">
            <Input placeholder="Name*" />
            <Input placeholder="Email*" />
            <Input placeholder="Phone*" />
            <Input placeholder="Topic" />
        </form>
        <button className="bg-primary text-white text-sm md:text-base lg:text-lg px-4 py-2 text-center">Send</button>
    </div>
</HomePageSection>

/*
 * Generic Styled Components
 */

interface HomePageSectionProps extends IChildrenProps {
    className: string
}

export const HomePageSection: React.FC<HomePageSectionProps> = ({ children, className = "" }) => <div className={`p-16 ${className}`}>
    {children}
</div>

interface AnchorButtonProps extends IChildrenProps {
    className: string,
    href: string
}

export const AnchorButton: React.FC<AnchorButtonProps> = ({ children, className = "", href }) => <a href={href} className={`cursor-pointer bg-primary text-white text-base md:text-lg lg:text-xl px-2 py-3 text-center ${className}`}>
    <span className="opacity-100">{children}</span>
</a>

interface InputProps {
    placeholder: string
}

export const Input: React.FC<InputProps> = ({ placeholder }) => <input type="text" placeholder={placeholder} className="border border-dark p-2 text-sm md:text-base lg:text-lg" />