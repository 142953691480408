import { TeamMemberDataType, TeamMembers } from './TeamData';
// Images
import LandingImage from 'images/landing.png';
import AquaponicsImage from 'images/aquaponics.png';
import DataAquisitionImage from 'images/data_acquisition.png';
import HeatAndPowerImage from 'images/heat_and_power.png';
import LightingImage from 'images/lighting.png';
import PosterImage from 'images/poster.png';
import { ourWorkAquaponicsHashCode, ourWorkHeatAndPowerHashCode, ourWorkInternetOfThingsHashCode, ourWorkLightingHashCode, ourWorkPageRoute } from './OurWorkPageConstants';


// Types
export type HomePageDataType = {
    heroSectionData: HeroSectionDataType,
    dividerContent: Array<string>,
    offeringSectionData: OfferingSectionDataType,
    posterSectionData: PosterSectionDataType,
    ourTeamSectionData: OurTeamSectionDataType
}

export type HeroSectionDataType = {
    image: string,
    header: string,
    content: string,
    btnLabel: string,
    btnHref: string
}

export type OfferingSectionDataType = {
    cards: Array<OfferingCardDataType>,
    cardBtnLabel: string
}

export type OfferingCardDataType = {
    image: string,
    title: string,
    content: string,
    href: string,
    btnLabel: string
}

export type PosterSectionDataType = {
    image: string
}

export type OurTeamSectionDataType = {
    title: string,
    members: Array<TeamMemberDataType>,
    btnLabel: string,
    btnHref: string
}

// Data
export const homePageRoute: string = "/"
export const homePageLinkLabel: string = "Home"

export const HeroImageHeader: string = "Help Us Grow!";

export const HeroImageContent: string = "Turnkey Aquaponics is investing in our Canadian manufacturing capacity. As a student founded company, financing options are limited. We are looking to our friends and community to help us grow. Learn how you can help!";

export const HeroImageButtonLabel: string = "Learn More";

export const HeroImageButtonHref: string = ourWorkPageRoute;

export const FirstDividerContent: string = "We provide technology-enabled solutions that empower growers to adopt turnkey solutions.\n" +
    "The result? A better return on grower's investment in sustainable solutions.";

export const OfferingCardButtonLabel: string = "Learn More";

export const OfferingCards: Array<OfferingCardDataType> = [{
    image: AquaponicsImage,
    title: "Aquaponics",
    content: "The growing global population and reduction of arable land due to climate change are major challenges in meeting global food demand. Controlled Environment Agriculture (CEA) presents the solution. Aquaponics is a form of CEA which combines hydroponics, the soil-less cultivation of plants, with aquaculture or fish farming. This method reduces land requirements and mitigates the effect of environmental conditions, ensuring a reliable supply of fresh, healthy food options.",
    href: `${ourWorkPageRoute}${ourWorkAquaponicsHashCode}`,
    btnLabel: OfferingCardButtonLabel
}, {
    image: HeatAndPowerImage,
    title: "Heat and Power",
    content: "High utility costs are a major barrier to Controlled Environment Agriculture. The integrated Combined Heat and Power unit (CHP) will generate electrical heat and power by utilizing the waste material from harvesting and plant maintenance to generate fuel that will be converted into electricity and heat. The CHP system will reduce the waste and high utility costs. ",
    href: `${ourWorkPageRoute}${ourWorkHeatAndPowerHashCode}`,
    btnLabel: OfferingCardButtonLabel
}, {
    image: LightingImage,
    title: "Lighting",
    content: "A major limitation of Controlled Environment Agriculture is the high consumption of electricity due to lighting and the associated costs. Turnkey Aquaponics Inc. offers the Cultivar LED grow light product line. The Cultivar offers significant reduction in power consumption while also offering a superior light spectrum that promotes plant growth. ",
    href: `${ourWorkPageRoute}${ourWorkLightingHashCode}`,
    btnLabel: OfferingCardButtonLabel
}, {
    image: DataAquisitionImage,
    title: "DAQ and Control",
    content: "Overhead and operation complexities are a major cost driver for Controlled Environment Agriculture. The Internet of Things globally connects technology, networking and automating everyday objects. It will be utilized to optimize and control CEA activities to further reduce labor costs and increase productivity. ",
    href: `${ourWorkPageRoute}${ourWorkInternetOfThingsHashCode}`,
    btnLabel: OfferingCardButtonLabel
}];

export const SecondDividerContent: string = "What sets us apart?\n" +
    "WE BRING IT ALL TOGETHER!";

export const OurTeamSectionTitle: string = "Our Team";

export const OurTeamBtnLabel: string = "Meet Our Team";

export const OurTeamBtnHref: string = "#";

// Data Object

export const homePageData: HomePageDataType = {
    heroSectionData: {
        image: LandingImage,
        header: HeroImageHeader,
        content: HeroImageContent,
        btnLabel: HeroImageButtonLabel,
        btnHref: HeroImageButtonHref
    },
    dividerContent: [
        FirstDividerContent,
        SecondDividerContent
    ],
    offeringSectionData: {
        cards: OfferingCards,
        cardBtnLabel: OfferingCardButtonLabel
    },
    posterSectionData: {
        image: PosterImage
    },
    ourTeamSectionData: {
        title: OurTeamSectionTitle,
        members: TeamMembers,
        btnLabel: OurTeamBtnLabel,
        btnHref: OurTeamBtnHref
    }
}