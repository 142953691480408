import { ContentPageSectionDataType, ProductContentPageDataType } from "."
// Images
import MissionImage from "images/mission_image.jpg"

// Types
export type ProductPageDataType = {
    sections: ContentPageSectionDataType
}

// Data
export const productPageRoute: string = "/products"
export const productPageLinkLabel: string = "Products"

export const ledLightsPageData: ProductContentPageDataType = {
    title: "LED Lights",
    image: MissionImage,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque quis pulvinar lacus.Vestibulum porta arcu et mi vestibulum hendrerit.Fusce sollicitudin lacinia sapien elementum cursus.Donec cursus pellentesque varius.Praesent id convallis sapien, et sodales turpis.Suspendisse neque ante, semper id fringilla sed, accumsan a ipsum.Phasellus eu erat ultricies, mattis mi non, tincidunt dui.Curabitur nec pulvinar ex, nec luctus odio.Nunc et vulputate magna.Nullam in risus egestas, varius dolor id, mattis neque.",
    tabs: [{
        title: "Tab1",
        content: [{
            type: "text",
            value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque quis pulvinar lacus.Vestibulum porta arcu et mi vestibulum hendrerit.Fusce sollicitudin lacinia sapien elementum cursus.Donec cursus pellentesque varius.Praesent id convallis sapien, et sodales turpis.Suspendisse neque ante, semper id fringilla sed, accumsan a ipsum.Phasellus eu erat ultricies, mattis mi non, tincidunt dui.Curabitur nec pulvinar ex, nec luctus odio.Nunc et vulputate magna.Nullam in risus egestas, varius dolor id, mattis neque."
        }]
    }, {
        title: "Tab2",
        content: [{
            type: "text",
            value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque quis pulvinar lacus.Vestibulum porta arcu et mi vestibulum hendrerit.Fusce sollicitudin lacinia sapien elementum cursus.Donec cursus pellentesque varius.Praesent id convallis sapien, et sodales turpis.Suspendisse neque ante, semper id fringilla sed, accumsan a ipsum.Phasellus eu erat ultricies, mattis mi non, tincidunt dui.Curabitur nec pulvinar ex, nec luctus odio.Nunc et vulputate magna.Nullam in risus egestas, varius dolor id, mattis neque."
        }]
    }, {
        title: "Tab3",
        content: [{
            type: "text",
            value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque quis pulvinar lacus.Vestibulum porta arcu et mi vestibulum hendrerit.Fusce sollicitudin lacinia sapien elementum cursus.Donec cursus pellentesque varius.Praesent id convallis sapien, et sodales turpis.Suspendisse neque ante, semper id fringilla sed, accumsan a ipsum.Phasellus eu erat ultricies, mattis mi non, tincidunt dui.Curabitur nec pulvinar ex, nec luctus odio.Nunc et vulputate magna.Nullam in risus egestas, varius dolor id, mattis neque."
        }]
    }]
}

export const cchpPageData: ProductContentPageDataType = {
    title: "CCHP",
    image: MissionImage,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque quis pulvinar lacus.Vestibulum porta arcu et mi vestibulum hendrerit.Fusce sollicitudin lacinia sapien elementum cursus.Donec cursus pellentesque varius.Praesent id convallis sapien, et sodales turpis.Suspendisse neque ante, semper id fringilla sed, accumsan a ipsum.Phasellus eu erat ultricies, mattis mi non, tincidunt dui.Curabitur nec pulvinar ex, nec luctus odio.Nunc et vulputate magna.Nullam in risus egestas, varius dolor id, mattis neque.",
    tabs: [{
        title: "Tab1",
        content: [{
            type: "text",
            value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque quis pulvinar lacus.Vestibulum porta arcu et mi vestibulum hendrerit.Fusce sollicitudin lacinia sapien elementum cursus.Donec cursus pellentesque varius.Praesent id convallis sapien, et sodales turpis.Suspendisse neque ante, semper id fringilla sed, accumsan a ipsum.Phasellus eu erat ultricies, mattis mi non, tincidunt dui.Curabitur nec pulvinar ex, nec luctus odio.Nunc et vulputate magna.Nullam in risus egestas, varius dolor id, mattis neque."
        }]
    }, {
        title: "Tab2",
        content: [{
            type: "text",
            value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque quis pulvinar lacus.Vestibulum porta arcu et mi vestibulum hendrerit.Fusce sollicitudin lacinia sapien elementum cursus.Donec cursus pellentesque varius.Praesent id convallis sapien, et sodales turpis.Suspendisse neque ante, semper id fringilla sed, accumsan a ipsum.Phasellus eu erat ultricies, mattis mi non, tincidunt dui.Curabitur nec pulvinar ex, nec luctus odio.Nunc et vulputate magna.Nullam in risus egestas, varius dolor id, mattis neque."
        }]
    }, {
        title: "Tab3",
        content: [{
            type: "text",
            value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque quis pulvinar lacus.Vestibulum porta arcu et mi vestibulum hendrerit.Fusce sollicitudin lacinia sapien elementum cursus.Donec cursus pellentesque varius.Praesent id convallis sapien, et sodales turpis.Suspendisse neque ante, semper id fringilla sed, accumsan a ipsum.Phasellus eu erat ultricies, mattis mi non, tincidunt dui.Curabitur nec pulvinar ex, nec luctus odio.Nunc et vulputate magna.Nullam in risus egestas, varius dolor id, mattis neque."
        }]
    }]
}

export const ematPageData: ProductContentPageDataType = {
    title: "EMAT",
    image: MissionImage,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque quis pulvinar lacus.Vestibulum porta arcu et mi vestibulum hendrerit.Fusce sollicitudin lacinia sapien elementum cursus.Donec cursus pellentesque varius.Praesent id convallis sapien, et sodales turpis.Suspendisse neque ante, semper id fringilla sed, accumsan a ipsum.Phasellus eu erat ultricies, mattis mi non, tincidunt dui.Curabitur nec pulvinar ex, nec luctus odio.Nunc et vulputate magna.Nullam in risus egestas, varius dolor id, mattis neque.",
    tabs: [{
        title: "Tab1",
        content: [{
            type: "text",
            value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque quis pulvinar lacus.Vestibulum porta arcu et mi vestibulum hendrerit.Fusce sollicitudin lacinia sapien elementum cursus.Donec cursus pellentesque varius.Praesent id convallis sapien, et sodales turpis.Suspendisse neque ante, semper id fringilla sed, accumsan a ipsum.Phasellus eu erat ultricies, mattis mi non, tincidunt dui.Curabitur nec pulvinar ex, nec luctus odio.Nunc et vulputate magna.Nullam in risus egestas, varius dolor id, mattis neque."
        }]
    }, {
        title: "Tab2",
        content: [{
            type: "text",
            value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque quis pulvinar lacus.Vestibulum porta arcu et mi vestibulum hendrerit.Fusce sollicitudin lacinia sapien elementum cursus.Donec cursus pellentesque varius.Praesent id convallis sapien, et sodales turpis.Suspendisse neque ante, semper id fringilla sed, accumsan a ipsum.Phasellus eu erat ultricies, mattis mi non, tincidunt dui.Curabitur nec pulvinar ex, nec luctus odio.Nunc et vulputate magna.Nullam in risus egestas, varius dolor id, mattis neque."
        }]
    }, {
        title: "Tab3",
        content: [{
            type: "text",
            value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque quis pulvinar lacus.Vestibulum porta arcu et mi vestibulum hendrerit.Fusce sollicitudin lacinia sapien elementum cursus.Donec cursus pellentesque varius.Praesent id convallis sapien, et sodales turpis.Suspendisse neque ante, semper id fringilla sed, accumsan a ipsum.Phasellus eu erat ultricies, mattis mi non, tincidunt dui.Curabitur nec pulvinar ex, nec luctus odio.Nunc et vulputate magna.Nullam in risus egestas, varius dolor id, mattis neque."
        }]
    }]
}

// Data Object
export const productPageData: ProductPageDataType = {
    sections: [
        ledLightsPageData,
        cchpPageData,
        ematPageData
    ]
}