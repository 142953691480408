import { ContentPageDataType, ContentPageSectionDataType, TeamContentPageDataType, TeamMemberDataType, TeamMembers } from "."
// Images
import MissionImage from "images/mission_image.jpg"

// Types
export type AboutPageDataType = {
    sections: ContentPageSectionDataType
}

// Data
export const aboutPageRoute: string = "/about"
export const aboutPageLinkLabel: string = "About"

export const missionPageData: ContentPageDataType = {
    title: "Mission",
    content: [{
        type: "image",
        value: MissionImage
    }, {
        type: "text",
        value: "Turnkey Aquaponics is a start-up that is focused on addressing the economic feasibility of Controlled Environment Agriculture by designing, manufacturing and providing innovative engineering solutions. We specialize in Aquaponics, which is a form of agriculture that combines Hydroponics, the growing of plants in water, and Aquaculture, the farming of aquatic organisms, into a symbiotic self-contained system."
    }]
}
export const companyHistoryPageData: ContentPageDataType = {
    title: "Company History",
    content: [{
        type: "text",
        value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque quis pulvinar lacus.Vestibulum porta arcu et mi vestibulum hendrerit.Fusce sollicitudin lacinia sapien elementum cursus.Donec cursus pellentesque varius.Praesent id convallis sapien, et sodales turpis.Suspendisse neque ante, semper id fringilla sed, accumsan a ipsum.Phasellus eu erat ultricies, mattis mi non, tincidunt dui.Curabitur nec pulvinar ex, nec luctus odio.Nunc et vulputate magna.Nullam in risus egestas, varius dolor id, mattis neque.\n\n"
            + "Morbi vitae iaculis nibh.Ut aliquam tempor auctor.Cras tortor sapien, ultricies eu mattis eu, feugiat vitae arcu.Vestibulum porta vulputate arcu, in lobortis justo faucibus ac.Nulla in bibendum sapien.Maecenas faucibus, nulla at laoreet placerat, turpis ipsum vulputate eros, pharetra convallis mauris felis non urna.Duis lacinia feugiat est non imperdiet.Nam nulla elit, feugiat quis volutpat et, consectetur nec ipsum.Morbi ullamcorper finibus lorem, sed malesuada dui lacinia non.Aenean commodo orci cursus urna cursus iaculis.Proin laoreet convallis ante, id facilisis mi sollicitudin a.Morbi tempus interdum iaculis.Vivamus non augue eu est lacinia euismod quis non tellus.\n\n"
            + "Donec tristique imperdiet condimentum.Sed posuere sagittis lacinia.In auctor justo vel euismod scelerisque.Aliquam in consectetur metus.Donec est urna, bibendum in porta at, dignissim eu elit.Integer scelerisque, augue et lacinia dignissim, ipsum eros molestie libero, lacinia varius ante tortor a ante.Sed facilisis congue enim ut pulvinar.Etiam ex erat, commodo finibus rhoncus a, feugiat quis mi.Nulla feugiat mi ut libero dictum, id dignissim lectus posuere.Vestibulum eros sem, mattis ut dolor ut, feugiat imperdiet magna.Aliquam erat volutpat.\n\n"
            + "In hac habitasse platea dictumst.Phasellus hendrerit viverra arcu, sed tincidunt ligula fermentum nec.Morbi hendrerit pulvinar est, nec sagittis arcu sollicitudin a.Integer enim ipsum, mattis sit amet sapien ac, accumsan viverra quam.Nam pretium ultricies nisi, quis volutpat nibh condimentum et.Vivamus id orci in ex facilisis aliquet.Pellentesque dignissim, arcu quis luctus fermentum, orci orci porttitor purus, in porttitor elit elit quis mauris.Praesent et molestie tellus.Morbi sodales risus turpis, quis accumsan enim sollicitudin vitae.Etiam eget nunc facilisis, luctus sapien sed, tempus mi.Aenean non hendrerit lacus, a lacinia nisi.Interdum et malesuada fames ac ante ipsum primis in faucibus.Nulla facilisi.Vivamus bibendum lacinia justo a blandit.Pellentesque aliquet accumsan pulvinar.\n\n"
            + "Donec molestie ornare hendrerit.Mauris odio nisl, mollis vel laoreet sollicitudin, luctus non lacus.Fusce ut urna nulla.Cras ex nibh, sodales vel tempus tincidunt, commodo eu nibh.Aenean vehicula ex ipsum, nec bibendum ipsum pretium ac.Integer vulputate mauris dui, eu placerat sem consectetur nec.Morbi dictum leo in neque sodales imperdiet.Maecenas in ultrices tortor, at mollis elit."
    }]
}

export const teamMemberPageData: TeamContentPageDataType = {
    title: "Our Team",
    members: TeamMembers
}

export const aboutTeamHashCode: string = "#our-team"

// DataObject
export const aboutPageData: AboutPageDataType = {
    sections: [
        missionPageData,
        companyHistoryPageData,
        teamMemberPageData
    ]
}