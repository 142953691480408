import { TeamMemberDataType } from "."

// Types
export type ContentPageSectionDataType = Array<ContentPageDataType | TeamContentPageDataType | ProductContentPageDataType>

export type ContentPageDataType = {
    title: string,
    content: Array<ContentDataType>,
}

export type ContentDataType = {
    type: "text" | "image",
    value: string
}

export type TeamContentPageDataType = {
    title: string,
    members: Array<TeamMemberDataType>
}

export type ProductContentPageDataType = {
    title: string,
    image: string,
    description: string,
    tabs: Array<ProductTabDataType>
}

export type ProductTabDataType = {
    title: string,
    content: Array<ContentDataType>
}