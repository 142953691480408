type CompanyDataType = {
    companyName: string
}

export const CompanyName = "Turnkey";

export const FooterText = "Copyright Turnkey Aquaponics Inc. 2023"

export const CompanyData = {
    companyName: CompanyName
}