import * as React from "react";
import NavBar from "./NavBar";
import IChildrenProps from "interfaces/IChildrenProps";
import Footer from "./Footer";

interface BasePageProps extends IChildrenProps { }

const BasePage: React.FC<BasePageProps> = ({ children }) => <div className="font-sans relative">
    <NavBar />
    <div className="mb-auto">
        {children}
    </div>
    <Footer />
</div>

export default BasePage;