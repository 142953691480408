// Images
import TeamMemberImage from 'images/team_member.png';

// Types
export type TeamMemberDataType = {
    image: any,
    name: string,
    title: string,
    qualifications: Array<string>
}

// Data
export const TeamMembers: Array<TeamMemberDataType> = [{
    image: TeamMemberImage,
    name: "Team Member",
    title: "Title",
    qualifications: ["Qualification"]
}, {
    image: TeamMemberImage,
    name: "Team Member",
    title: "Title",
    qualifications: ["Qualification"]
}, {
    image: TeamMemberImage,
    name: "Team Member",
    title: "Title",
    qualifications: ["Qualification"]
}, {
    image: TeamMemberImage,
    name: "Team Member",
    title: "Title",
    qualifications: ["Qualification"]
}, {
    image: TeamMemberImage,
    name: "Team Member",
    title: "Title",
    qualifications: ["Qualification"]
}, {
    image: TeamMemberImage,
    name: "Team Member",
    title: "Title",
    qualifications: ["Qualification"]
}];