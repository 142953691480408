import * as React from "react";
import IChildrenProps from "interfaces/IChildrenProps"

interface SectionHeaderProps extends IChildrenProps {
    className: string
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ children, className = "" }) => <>
    <h2 className={`text-lg md:text-xl lg:text-2xl ${className}`}>{children}</h2>
    <hr className="w-8 md:w-12 lg:w-16 border border-2 border-primary mb-4" />
</>

export default SectionHeader;