import * as React from "react"

export type SideBarDataType = {
    title: string,
    [key: string]: any
}

export interface SideBarProps {
    data: Array<SideBarDataType>,
    active: number,
    setActive: React.Dispatch<React.SetStateAction<number>>
}

const SideBar: React.FC<SideBarProps> = ({ data, active, setActive }) => <div className="m-2 md:m-4 col-span-1 font-nav">
    <ul className="font-medium flex flex-col border border-white rounded-none bg-gray-50">
        {data.map((item, index) => <li className={`block ${active == index ? "bg-primary" : "hover:bg-gray-100"} p-2 cursor-pointer`} onClick={() => setActive(index)} key={`sidebar-list-item-${index}`}>
            <span className={`${active == index ? "text-white" : "text-primary"} font-bold`}>{">"}</span> {item.title}
        </li>)}
    </ul>
</div>

export default SideBar;