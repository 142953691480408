import * as React from "react";
import logo from 'images/logo.png';
import { CompanyName, homePageLinkLabel, homePageRoute } from "data/index";
import IChildrenProps from "interfaces/IChildrenProps"
import { aboutPageLinkLabel, aboutPageRoute } from "../data/AboutPageConstants";
import { ourWorkPageLinkPabel, ourWorkPageRoute } from "../data/OurWorkPageConstants";
import { productPageLinkLabel, productPageRoute } from "../data/ProductPageConstants";
import { useLocation } from "react-router-dom";

const getVariant = (route: string, pathname: string) => {
    if (route == pathname) return "primary"
    return "standard"
}

const NavBar: React.FC = () => {
    const [show, setShow] = React.useState(false);
    const { pathname } = useLocation()

    return <nav className="bg-white text-primary border-b-4 border-separator">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <NavBarLogo href={""} />
            <NavBarCollapseButton navbarId={"navbar-default"} setShow={setShow} show={show} />
            <NavBarAnchorWrapper show={show}>
                <NavBarAnchorItem
                    href={homePageRoute}
                    variant={getVariant(homePageRoute, pathname)}>
                    {homePageLinkLabel}
                </NavBarAnchorItem>
                <NavBarAnchorItem
                    href={aboutPageRoute}
                    variant={getVariant(aboutPageRoute, pathname)}>
                    {aboutPageLinkLabel}
                </NavBarAnchorItem>
                <NavBarAnchorItem
                    href={ourWorkPageRoute}
                    variant={getVariant(ourWorkPageRoute, pathname)}>
                    {ourWorkPageLinkPabel}
                </NavBarAnchorItem>
                <NavBarAnchorItem
                    href={productPageRoute}
                    variant={getVariant(productPageRoute, pathname)}>
                    {productPageLinkLabel}
                </NavBarAnchorItem>
                <NavBarAnchorItem href="/contact"
                    variant={getVariant("/contact", pathname)}>
                    Contact
                </NavBarAnchorItem>
            </NavBarAnchorWrapper>
        </div>
    </nav>
}

export default NavBar;

interface NavBarLogoProps {
    href: string
}

export const NavBarLogo: React.FC<NavBarLogoProps> = ({ href }) => <a href={href} className="flex items-center">
    <img src={logo} className="h-14 mr-3" alt={`${CompanyName} Logo`} />
</a>

interface NavBarCollapseButtonProps {
    show: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const NavBarCollapseButton: React.FC<NavBarCollapseButtonProps> = ({ show, setShow }) => <button type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" onClick={() =>  setShow(!show)}>
    <span className="sr-only">Open main menu</span>
    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
    </svg>
</button>

interface NavBarAnchorWrapperProps extends IChildrenProps {
    show: boolean
}

export const NavBarAnchorWrapper: React.FC<NavBarAnchorWrapperProps> = ({ children, show }) => <div className={`${show ? '' : 'hidden'} w-full md:block md:w-auto`}>
    <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-none bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white">
        {children}
    </ul>
</div>

interface NavBarAnchorItem extends IChildrenProps {
    href: string,
    variant: "primary" | "standard"
}

export const NavBarAnchorItem: React.FC<NavBarAnchorItem> = ({ href, variant = "standard", children }) => {
    const variants = {
        primary: 'bg-transparent text-primary md:bg-primary md:hover:bg-primary md:text-white md:hover:text-white',
        standard: 'bg-transparent md:hover:bg-primary text-primary md:hover:text-white'
    }

    return <li>
        <a href={href} className={`block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:border-0 md:px-4 md:py-1 md:rounded-none ${variants[variant]}`}>{children}</a>
    </li>
}