import * as React from 'react'
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import './app.css';
import HomePage from 'components/HomePage';
import { aboutPageRoute } from './data/AboutPageConstants';
import AboutPage from './components/AboutPage';
import { ourWorkPageRoute } from './data/OurWorkPageConstants';
import OurWorkPage from './components/OurWorkPage';
import { homePageRoute } from './data';
import { productPageRoute } from './data/ProductPageConstants';
import ProductPage from './components/ProductPage';

export const App: React.FC = () => {
    return <BrowserRouter>
        <Routes>
            <Route path={homePageRoute} element={<HomePage />} />
            <Route path={aboutPageRoute} element={<AboutPage />} />
            <Route path={ourWorkPageRoute} element={<OurWorkPage />} />
            <Route path={productPageRoute} element={<ProductPage />} />
        </Routes>
    </BrowserRouter>
}

const root = createRoot(document.getElementById('root'))
root.render(<App />)